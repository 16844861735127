import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection, SplitConfig, Container } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { FamilyCompaniesContent } from "@components/FamilyOfCompanies/Content";
import { CompanyCard } from "@components/FamilyOfCompanies/Company/Company";
import { useApplication } from "@ryerson/frontend.application";

export type FamilyOfCompaniesCompany = {
	imageUrl: string;
	url: string;
};

export type FamilyOfCompaniesContent = {
	title: string;
	subTitle: string;
	tagLine: string[];
	videoOverlay: string;
	videoId?: string;
	videoThumbnail: string;
	description: string;
	hideFamilies?: boolean;
	actionLabel?: string | string[];
	actionUrl?: string;
};

export type FamilyOfCompaniesProps = {
	content: FamilyOfCompaniesContent;
};

const ParallaxContainer = styled.div`
	position: absolute;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 0;
`;

const EndingSpacer = styled.div`
	display: block;
	width: 100%;
	height: 200px;
	position: relative;
	z-index: -2;
`;

const LogoContainer = styled.div`
	width: 100%;
	height: auto;
	img {
		width: 100%;
		height: auto;
	}
`;

const TagLines = styled.div`
	width: 360px;
	height: auto;
	margin-left: auto;
	margin-top: 110px;
`;

const FamiliesContainer = styled.div`
	width: 100%;
	height: auto;
	margin-top: 150px;
`;

const FamiliesContent = styled.div`
	width: 100%;
	max-width: 260px;
	height: auto;
`;

const FamiliesButtonLink = styled.div`
	width: 100%;
	margin-top: 25px;
	height: auto;
`;

const FamiliesLeft = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 40%;
	padding: 80px;
`;

const FamiliesRight = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 60%;
`;

const GridBox = styled.div`
	width: 100%;
	height: 120px;
	box-sizing: border-box;
	position: relative;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				height: 80px;
			}
		`;
	}}
`;

const GridContainer = styled.div`
	width: 100%;
	margin-top: 35px;
	height: auto;
	margin-bottom: 15px;
`;

const FamilyOfCompanies: React.FC<FamilyOfCompaniesProps> = ({ content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const split: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.darkerGray,
				percentage: "100%",
			},
		],
	};
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ParallaxProvider>
					<ContentSection type="split" split={split} vPadding="270px">
						<ParallaxContainer>
							<Parallax y={["-80px", "-340px"]}>
								<Container
									css={css`
										background-color: transparent;
									`}
									vPadding="0"
								>
									<LogoContainer>
										<img src="/images/RyersonLogo.png" />
									</LogoContainer>
									<Typography
										css={css`
											text-align: right;
											letter-spacing: -1.5px;
										`}
										variant="h2"
										color={theme.colors.primary.white}
									>
										{content.subTitle}
									</Typography>
									<TagLines>
										{content.tagLine.map((tag: string, index: number) => {
											return (
												<Typography
													key={index}
													weight="bold"
													css={css`
														line-height: 35px;
														margin: 0;
													`}
													color={
														index === content.tagLine.length - 1
															? theme.colors.primary.white
															: theme.colors.tertiary.text
													}
													variant="h3"
												>
													{tag}
												</Typography>
											);
										})}
									</TagLines>
									<FamiliesContainer
										css={css`
											background-color: ${theme.colors.primary.darkGray};
										`}
									>
										<FamiliesLeft>
											<FamiliesContent>
												<Typography
													color={theme.colors.primary.white}
													size="md"
												>
													{content.description}
												</Typography>
												{content.actionUrl && content.actionLabel ? (
													<FamiliesButtonLink>
														<ButtonLink
															gatsby={true}
															to={content.actionUrl}
															label={content.actionLabel}
															type="tertiary"
														/>
													</FamiliesButtonLink>
												) : (
													<></>
												)}
											</FamiliesContent>
										</FamiliesLeft>
										{content.hideFamilies && content.hideFamilies === true ? (
											<></>
										) : (
											<FamiliesRight
												css={css`
													border-left: 1px solid
														${theme.colors.primary.gray};
												`}
											>
												<Grid gutter={0}>
													<Row gutter={0}>
														<Column
															align="center"
															md={3}
															lg={3}
															xl={3}
															gutter={0}
														>
															<GridBox
																theme={theme}
																css={css`
																	border-right: 1px solid
																		${theme.colors.primary.gray};
																	border-bottom: 1px solid
																		${theme.colors.primary.gray};
																`}
															>
																<img
																	css={css`
																		max-width: 110px;
																		max-height: 69px;
																		position: absolute;
																		top: 0;
																		left: 0;
																		bottom: 0;
																		right: 0;
																		margin: auto;
																	`}
																	src={
																		"/images/family-of-companies/family-of-companies-logo-small.png"
																	}
																/>
															</GridBox>
														</Column>

														{FamilyCompaniesContent[
															language
														].companies.map(
															(
																company: CompanyCard,
																index: number
															) => {
																return (
																	<Column
																		key={index}
																		align="center"
																		md={3}
																		lg={3}
																		xl={3}
																		gutter={0}
																	>
																		{process.env
																			.GATSBY_COMPANY ===
																		"southernToolSteel" ? (
																			<GridBox
																				theme={theme}
																				css={css`
																					border-right: 1px
																						solid
																						${(index +
																							2) %
																							4 ===
																						0
																							? "transparent"
																							: theme
																									.colors
																									.primary
																									.gray};
																					border-bottom: 1px
																						solid
																						${index > 12
																							? "transparent"
																							: theme
																									.colors
																									.primary
																									.gray};
																				`}
																			>
																				<img
																					css={css`
																						max-width: 110px;
																						max-height: 70px;
																						position: absolute;
																						top: 0;
																						left: 0;
																						bottom: 0;
																						right: 0;
																						margin: auto;
																					`}
																					src={
																						company
																							.companyDetails
																							.logoImageUrl
																					}
																				/>
																			</GridBox>
																		) : (
																			<Link
																				gatsby
																				to={
																					"/about-us/family-of-companies#" +
																					company.companyDetails.companyName
																						.toLowerCase()
																						.replace(
																							/\s+/g,
																							"-"
																						)
																				}
																			>
																				<GridBox
																					theme={theme}
																					css={css`
																						border-right: 1px
																							solid
																							${(index +
																								2) %
																								4 ===
																							0
																								? "transparent"
																								: theme
																										.colors
																										.primary
																										.gray};
																						border-bottom: 1px
																							solid
																							${index >
																							12
																								? "transparent"
																								: theme
																										.colors
																										.primary
																										.gray};
																					`}
																				>
																					<img
																						css={css`
																							max-width: 110px;
																							max-height: 70px;
																							position: absolute;
																							top: 0;
																							left: 0;
																							bottom: 0;
																							right: 0;
																							margin: auto;
																						`}
																						src={
																							company
																								.companyDetails
																								.logoImageUrl
																						}
																					/>
																				</GridBox>
																			</Link>
																		)}
																	</Column>
																);
															}
														)}
														<Column
															align="center"
															md={4}
															lg={3}
															xl={3}
															gutter={0}
														>
															<GridBox
																theme={theme}
																css={css`
																	border-right: 1px solid
																		${theme.colors.primary.gray};
																	border-bottom: 1px solid
																		${theme.colors.primary.gray};
																`}
															/>
														</Column>
													</Row>
												</Grid>
											</FamiliesRight>
										)}
									</FamiliesContainer>
								</Container>
							</Parallax>
						</ParallaxContainer>
						<img src={content.videoThumbnail} />
					</ContentSection>
					<EndingSpacer />
				</ParallaxProvider>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="30px" hPadding="0">
					{content.tagLine.map((tag: string, index: number) => {
						return (
							<Typography
								key={index}
								css={css`
									margin: 0px;
									line-height: 25px;
									font-size: 20px;
								`}
								weight="bold"
								color={
									index === content.tagLine.length - 1
										? theme.colors.primary.header
										: theme.colors.primary.gray
								}
								variant="h3"
							>
								{tag}
							</Typography>
						);
					})}
					<img
						src={content.videoThumbnail}
						css={css`
							width: 100%;
							height: auto;
						`}
					/>
					<Typography size="md">{content.description}</Typography>
					{content.hideFamilies && content.hideFamilies === true ? (
						<></>
					) : (
						<GridContainer
							css={css`
								background-color: ${theme.colors.primary.darkGray};
							`}
						>
							<Grid gutter={0}>
								<Row gutter={0}>
									<Column xs={6} sm={6} md={6} lg={6} xl={6} gutter={0}>
										<GridBox
											theme={theme}
											css={css`
												border-right: 1px solid ${theme.colors.primary.gray};
												border-bottom: 1px solid
													${theme.colors.primary.gray};
											`}
										>
											<img
												css={css`
													max-width: 75px;
													max-height: 53px;
													position: absolute;
													top: 0;
													left: 0;
													bottom: 0;
													right: 0;
													margin: auto;
												`}
												src={
													"/images/family-of-companies/family-of-companies-logo-small.png"
												}
											/>
										</GridBox>
									</Column>
									{FamilyCompaniesContent[language].companies.map(
										(company: CompanyCard, index: number) => {
											return (
												<Column
													key={index}
													xs={6}
													sm={6}
													md={6}
													lg={6}
													xl={6}
													gutter={0}
												>
													<Link
														to={
															"/about-us/family-of-companies#" +
															company.companyDetails.companyName
																.toLowerCase()
																.replace(/\s+/g, "-")
														}
														gatsby
													>
														<GridBox
															theme={theme}
															css={css`
																border-right: 1px solid
																	${(index + 1) % 2 === 0
																		? theme.colors.primary.gray
																		: "transparent"};
																border-bottom: 1px solid
																	${index > 12
																		? "transparent"
																		: theme.colors.primary
																				.gray};
															`}
														>
															<img
																css={css`
																	max-width: 75px;
																	max-height: 53px;
																	position: absolute;
																	top: 0;
																	left: 0;
																	bottom: 0;
																	right: 0;
																	margin: auto;
																`}
																src={
																	company.companyDetails
																		.logoImageUrl
																}
															/>
														</GridBox>
													</Link>
												</Column>
											);
										}
									)}
								</Row>
							</Grid>
						</GridContainer>
					)}

					{content.actionLabel && content.actionUrl ? (
						<FamiliesButtonLink
							css={css`
								margin-bottom: 40px;
							`}
						>
							<ButtonLink
								type="primary"
								label={content.actionLabel}
								to={content.actionUrl}
								gatsby={true}
							/>
						</FamiliesButtonLink>
					) : (
						<></>
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default FamilyOfCompanies;
